<template>
    <div>
        <div>
            <no-data v-if="List.list.length == 0"></no-data>
            <van-pull-refresh  v-model="isloading" @refresh="onRefresh" v-else>
                <van-list v-model="loading" :finished="finished" finished-text="加载完毕" :immediate-check="false" @load="loadData">
                    <div class="comment-wrap van-cell"  v-for="(item,index) in List.list" :key="index">
                        <div class="left">
                            <van-checkbox v-model="item.checked"  class="mr5" v-if="editing"/>
                            <van-image round  fit="fill"  width="1.2rem" height="1.2rem" lazy-load :src="item.banner_img" />
                        </div>
                        <div class="cen ml10 mr10">
                            <div class="name text-base font-16 clamp-1">{{item.activity_name}}</div>
                            <div class="time text-base font-12 clamp-1 text-grey ">{{item.createtime}}</div>
                            <div class="content text-base font-14 clamp-2">{{item.content}}</div>
                        </div>
                        <div class="right">
                            <div class="icon-box icon-box1">
                                <i class="iconfont icon-check-box" @click="toCheck(item.id,index)" v-if="item.status==0"></i>
                            </div>
                            <div class="icon-box">
                                <i class="iconfont icon-lajitong" @click="toDel(item.id,index)"></i>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    import { List, Cell, PullRefresh, Radio, Checkbox,Dialog } from "vant";
    import {user_commnet,del_comment} from '@/request/api';

    export default {
        components: {
            [List.name]: List,
            [Cell.name]: Cell,
            [PullRefresh.name]:PullRefresh,
            [Checkbox.name]:Checkbox
        },
        data() {
            return {
                List:{
                    list:[

                    ]
                },
                loading: false,
                finished: false,
                isloading:false,
                editing:false,
                checkall:false,
                page: 1
            };
        },
        mounted: function() {
            this.loadData();
        },
        methods: {
            async loadData() {
                // 获取评论列表
                await user_commnet({page: this.page}).then(res => {
                    if(res.code == 1){

                        //  还有更多数据page++
                        if (res.data.has_more == true){
                            this.page++
                            this.finished = false
                        } else {
                            //没有数据了
                            this.finished = true
                        }
                        this.List.list = this.List.list.concat(res.data.data)
                    } else {
                        this.$dialog({message:res.msg});
                    }
                }).catch(err => {
                    this.$dialog({
                        message: "评论数据获取失败，请重新刷新"
                    })
                })

                // 加载状态结束
                this.loading = false;
            },
            onRefresh(){
                let _selft = this;
                this.isloading = true;
                setTimeout(function(){
                    _selft.page = 1
                    _selft.List = {list:[]}
                    _selft.loadData()
                    _selft.isloading = false;
                },500);
                // this.loadData()
            },

            toCheck(type,index){
                if(type == 'all'){
                    this.checkall = true;
                    this.List.list.forEach(item => {
                        item.checked = this.checkall;
                    });

                }else if(type == 'cancel'){
                    this.checkall = false;
                    this.List.list.forEach(item => {
                        item.checked = this.checkall;
                    });

                }else if(type == 'item'){

                }

            },
            //删除
            toDel(id,index){
                Dialog.confirm({
                    title: '删除评论',
                    message: '是否删除评论'
                }).then(() => {
                    del_comment({id:id}).then(res => {
                        this.List.list.splice(index,1);
                    }).catch(err => {
                        this.$dialog({message:'删除失败,请稍后再试'})
                    })
                }).catch(() => {
                    // on cancel
                });
            }
        },

    };
</script>

<style lang="less" >
    //评论
    .comment-wrap{
        display: flex;
        flex: row nowrap;
        // align-content: center;
        transition:  all .2s ease;
        .left{
            flex: 1;
            display: flex;
            flex-flow: row nowrap;
            align-items: top;
        }
        .cen{
            flex: 9;

        }
        .right{
            flex:1;
            display: flex;
            flex-flow: column nowrap;
            .icon-box{
                height: 4px;
            }
            .icon-box1{
                height: 60%;
            }


        }
        .iconfont{
            font-size: 18px;
            color:#333;
            font-size: 23px;
        }
    }

</style>>

